@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@font-face {
  font-family: AvenirNest_Italic;
  src: url(/static/media/AvenirNextLTPro-It.5baa43d1.woff);
  font-style: italic;
}

@font-face {
  font-family: AvenirNest_Regular;
  src: url(/static/media/AvenirNextLTPro-Regular.c182d6e9.woff);
}

@font-face {
  font-family: AvenirNest_Bold;
  src: url(/static/media/AvenirNextLTPro-Bold.a55b857c.woff);
}

@font-face {
  font-family: ProximaNova_Regular;
  src: url(/static/media/ProximaNova-Regular.0693a83f.woff);
}

@font-face {
  font-family: ProximaNova_Bold;
  src: url("/static/media/Proxima Nova Bold.31b9e85e.woff");
}

:root {
  --color-main: #19D3C5;
  --color-darkMain: #97D9D4;
  --color-lightMain: #97D9D4;
  --color-secundary: #E7004C;
  --color-light-texts: #FFF;
  --color-dark-texts: #596674;
  --color-gray: #707070;
  --color-black: #0F0F0F;

}

body {
  margin: 0;
  padding: 0;
  font-family: AvenirNest_Regular;
  color: #FFF;
  color: var(--color-light-texts);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}


body #root {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
*::-webkit-scrollbar-track {
  background: #5554;
}
*::-webkit-scrollbar-thumb {
  background: #E7004C;
  background: var(--color-secundary);
  border-radius: 10px;
}
*::-webkit-scrollbar-thumb:hover {
  background: #E7004C;
  background: var(--color-secundary);
}

.swal2-popup {
  border-radius: 20px !important;
}

.swal2-title {
  padding: 0px !important;
}

.swal2-title br {
  font-size: 0 !important;
}

.swal2-image {
  margin: 3vw auto 1vw auto !important;
}

.closePopUp {
  position: absolute;
  top: 0%;
}

button.swal2-close {
  color: #E7004C;
  color: var(--color-secundary);
}

.closePopUp {
  position: absolute;
  top: 0%;
  right: 0%;
}

/* ============================== */
/* spin loader */

.spinLoader {
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;

  border: 2px solid transparent;
  border-top: 2px solid #FFF;
  border-top: 2px solid var(--color-light-texts);
  border-left: 2px solid #FFF;
  border-left: 2px solid var(--color-light-texts);
  border-bottom: 2px solid #FFF;
  border-bottom: 2px solid var(--color-light-texts);
  border-radius: 50%;

  margin: 0px auto;

  -webkit-animation-name: girar;

          animation-name: girar;
  -webkit-animation-duration: .9s;
          animation-duration: .9s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

@-webkit-keyframes girar {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes girar {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

